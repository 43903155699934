const mainTitle = 'Products Overview';

export const componentConfig = {
  cid: 'homePerformanceByDevicePie',
  id: 'homePerformanceByDevicePie',
  dataChecks: ['HasDisplay'],
  disabled: undefined,
  dataSource: 'HOME.ByDeviceTypeImpression',
  breakpoints: ['lg3', 'sm6'],
  name: 'genericPie',
  title: mainTitle,
  __typename: 'genericPie',
  exportableTableData: 'xls',
  filterable: true,
  hasBrandImage: undefined,
  hideIfNoData: undefined,
  nameKey: 'DeviceType',
  rootValueKey: 'HOME',
  selectedValueKey: true,
  stacked: false,
  tooltipType: 'creative/impressions',
  valueKey: ['Impressions'],
  tooltips: [
    {
      title: 'Other',
      message:
        'Represents Device Types that are unknown and cannot be categorized into one of the other Device Types listed.',
      key: undefined,
      __typename: 'Tooltip',
    },
    {
      title: 'CSV/XLSX export',
      message: 'Contains all Device Types.',
      key: undefined,
      __typename: 'Tooltip',
    },
  ],
  overrides: undefined,
  noDataTooltips: ['No data'],
  feedSource: undefined,
  tooltipsTitle: [
    {
      title: mainTitle,
      message: 'This shows the distribution of Impressions for all Tactics that have Active Campaigns.',
    },
  ],
};

export const componentHeight = '660px';

export const dataSource = 'HOME.ByDeviceTypeImpression';

export const sectionConfig = {
  id: 'home',
  enableCampaignSelection: true,
  fileNameTemplate: '${advertiserNameKey}-${tacticModule}-${fileDateTime}',
  pdfExport: 'dynamic/pdf/display',
  pptExport: 'qbr/ppt/display',
  pptExportDevOnly: false,
  pptExportLocalOnly: false,
  title: 'Home',
  xlsExport: 'dynamic/xls/display',
  xlsExportDevOnly: false,
  markup: ['CPM', 'CPC'],
  exportTab: null,
  xlsExportLocalOnly: null,
  __typename: 'Section',
  components: [
    {
      type: 'advertiser',
      components: [
        {
          cid: 'displayDMAZipMap',
          id: 'displayDMAZipMap',
          dataChecks: null,
          disabled: null,
          dataSource: 'HOME',
          breakpoints: ['lg12', 'sm12'],
          name: 'dmaZipMap',
          title: '',
          __typename: 'dmaZipMap',
          customStyle: null,
          hidePolygonsWithLessThan: null,
          legend: 'creative impressions',
          legendGradient: null,
          mapGradient: null,
          mapGradientOpacity: null,
          themes: ['satellite'],
          zoomToTopNPercents: 80,
          onlyMap: null,
          onlySummary: null,
          campaignSummary: {
            availableTopMetrics: ['Conversions', 'Clicks', 'ClickThrough', 'Pacing'],
            availableBottomMetrics: ['Reach', 'Frequency', 'SpendVal', 'CostPerClick'],
            topMetrics: ['Conversions', 'Clicks', 'ClickThrough', 'Pacing'],
            bottomMetrics: ['Reach', 'Frequency', 'SpendVal', 'CostPerClick'],
            hideZeros: null,
            replaceTitle: null,
            impressionsProgressBar: true,
            siteTrafficProgressBar: null,
            spendProgressBar: null,
            switchMetricDisplayOrder: null,
            webliftMetrics: null,
            customStyle: null,
            showSpendIfEnabled: null,
            __typename: 'CampaignSummary',
          },
          size: {
            height: 550,
            __typename: 'Size',
          },
          overrides: null,
          fallbackMap: null,
        },
        {
          cid: 'displayPerformanceByCreativeProgressBarTable',
          id: 'displayPerformanceByCreativeProgressBarTable',
          dataChecks: ['HasDisplay'],
          disabled: null,
          dataSource: 'DISPLAY.CreativeListByTactic',
          breakpoints: ['lg12', 'sm12'],
          name: 'progressBarTable',
          title: 'Performance By Creative',
          __typename: 'progressBarTable',
          availableBars: ['Impressions', 'Clicks', 'ClickThrough', 'Actions', 'Conversions'],
          bars: ['Impressions', 'Clicks', 'ClickThrough', 'Actions', 'Conversions'],
          creativeType: 'image',
          availableColumns: [
            'CreativeId',
            'CreativeName',
            'Impressions',
            'Clicks',
            'ClickThrough',
            'Tactic',
            'Actions',
            'Conversions',
          ],
          columns: [
            'CreativeId',
            'CreativeName',
            'Impressions',
            'Clicks',
            'ClickThrough',
            'Tactic',
            'Actions',
            'Conversions',
          ],
          columnCount: null,
          downloadTableData: 'csv',
          filterBy: 'Tactic',
          exportableTableData: 'xls',
          icon: null,
          itemLimit: null,
          hideIfNoData: null,
          sortBy: 'Impressions',
          searchEnabled: true,
          perPage: null,
          publishers: null,
          tooltips: [
            {
              key: null,
              message:
                'Percentage of times a user clicked on an ad (clicks/impressions). For Display, the industry average is 0.05% to 0.10%. For Preroll, industry average is 0.10% to 0.30%.',
              title: 'CTR (Click Through Rate)',
              __typename: 'Tooltip',
            },
          ],
          progressBarColor: null,
          overrides: null,
          noDataTooltips: null,
        },
        {
          cid: 'displayPerformanceByDevicePie',
          id: 'displayPerformanceByDevicePie',
          dataChecks: ['HasDisplay'],
          disabled: null,
          dataSource: 'DISPLAY.ByDeviceTypeImpression',
          breakpoints: ['lg6', 'sm12'],
          name: 'genericPie',
          title: 'Performance By Device Type',
          __typename: 'genericPie',
          exportableTableData: 'xls',
          filterable: true,
          hasBrandImage: null,
          hideIfNoData: null,
          nameKey: 'DeviceType',
          rootValueKey: 'DISPLAYTotal',
          tooltipType: 'creative/impressions',
          valueKey: ['Impressions', 'Clicks', 'ClickThrough'],
          tooltips: [
            {
              title: 'Other',
              message:
                'Represents Device Types that are unknown and cannot be categorized into one of the other Device Types listed.',
              key: null,
              __typename: 'Tooltip',
            },
            {
              title: 'CSV/XLSX export',
              message: 'Contains all Device Types.',
              key: null,
              __typename: 'Tooltip',
            },
          ],
          overrides: null,
          noDataTooltips: null,
          feedSource: null,
        },
        {
          cid: 'displayPerformanceByDaypartPieChart',
          id: 'displayPerformanceByDaypartPieChart',
          dataChecks: ['HasDisplay'],
          disabled: null,
          dataSource: 'DISPLAY.ByDaypartImpression',
          breakpoints: ['lg6', 'sm12'],
          name: 'genericPie',
          title: 'Performance By Daypart',
          __typename: 'genericPie',
          exportableTableData: 'xls',
          filterable: null,
          hasBrandImage: null,
          hideIfNoData: true,
          nameKey: 'Daypart',
          rootValueKey: 'DISPLAYTotal.Impressions',
          tooltipType: 'daypart/hour',
          valueKey: ['Impressions'],
          tooltips: [],
          overrides: null,
          noDataTooltips: null,
          feedSource: ['XANDR'],
        },
        {
          cid: 'displayPerformanceByDayOfWeekLineChart',
          id: 'displayPerformanceByDayOfWeekLineChart',
          dataChecks: ['HasDisplay'],
          disabled: null,
          dataSource: 'DISPLAY.DayOfWeekImpression',
          breakpoints: ['lg6', 'sm12'],
          name: 'genericLineChart',
          title: 'Performance By Day of Week',
          __typename: 'genericLineChart',
          chartColorIndex: null,
          chartType: 'smooth',
          commonTweaks: ['daybackfill'],
          dataKey: 'Day',
          exportableTableData: 'xls',
          hideEmptySeriesKeys: null,
          hideIfNoData: null,
          hideLegend: null,
          availableLineSeriesKeys: ['Impressions', 'ClickThrough'],
          lineSeriesKeys: ['Impressions', 'ClickThrough'],
          showyAxisSplitLine: null,
          total: null,
          useTacticSummaryData: null,
          xAxisIntervalCount: null,
          xAxisMaxItems: null,
          xAxisShowAll: true,
          xAxisSplitLineHide: null,
          yAxisHide: null,
          yAxisLineHide: null,
          xAxisRotateDeg: null,
          showOneLine: null,
          chartColors: ['#02e0ff', '#0042ee', '#5299ff', '#09447f'],
          sideTotalMetrics: null,
          tooltips: null,
          noDataTooltips: null,
          overrides: {
            id: null,
            breakpoints: null,
            title: null,
            lineSeriesKeys: null,
            showOneLine: null,
            chartColors: ['#02e0ff', '#0042ee', '#5299ff', '#09447f'],
            __typename: 'Overrides',
          },
          feedSource: null,
        },
        {
          cid: 'displayPerformanceBySiteTable',
          id: 'displayPerformanceBySiteTable',
          dataChecks: ['HasDisplay'],
          disabled: null,
          dataSource: 'DISPLAY.PublisherByTactic',
          breakpoints: ['lg6', 'sm12'],
          name: 'genericTable',
          title: 'Performance By Site',
          __typename: 'genericTable',
          columns: ['Publisher', 'Impressions', 'ImpressionPercent', 'Clicks', 'ClickThrough', 'Tactic', 'Conversions'],
          commonTweaks: null,
          dataChecksByCampaign: null,
          downloadTableData: 'csv',
          exportableTableData: 'xls',
          filterBy: 'Tactic',
          footer: null,
          hasProgressBars: null,
          hideIfNoData: null,
          icon: null,
          primaryStar: null,
          searchEnabled: true,
          sortBy: null,
          useTacticSummaryData: null,
          widths: null,
          availableColumns: [
            'Publisher',
            'Impressions',
            'ImpressionPercent',
            'Clicks',
            'ClickThrough',
            'Tactic',
            'Conversions',
          ],
          itemLimit: null,
          headers: [
            {
              align: 'left',
              sortable: true,
              text: 'Site',
              value: 'Publisher',
              width: '30%',
              __typename: 'TableHeader',
            },
            {
              align: 'left',
              sortable: true,
              text: 'Imps',
              value: 'Impressions',
              width: '15%',
              __typename: 'TableHeader',
            },
            {
              align: 'left',
              sortable: true,
              text: 'Imp %',
              value: 'ImpressionPercent',
              width: '20%',
              __typename: 'TableHeader',
            },
            {
              align: 'left',
              sortable: true,
              text: 'Clicks',
              value: 'Clicks',
              width: '15%',
              __typename: 'TableHeader',
            },
            {
              align: 'left',
              sortable: true,
              text: 'CTR',
              value: 'ClickThrough',
              width: '20%',
              __typename: 'TableHeader',
            },
          ],
          iconOverride: null,
          tooltips: [
            {
              key: null,
              message:
                'Percentage of the Total Impressions. It is calculated as Impressions divided by Total Impressions. Same as “% of Total”.',
              title: 'Impressions %',
              __typename: 'Tooltip',
            },
            {
              key: null,
              message:
                'Percentage of times a user clicked on an ad (clicks/impressions). For Display, the industry average is 0.05% to 0.10%. For Preroll, industry average is 0.10% to 0.30%.',
              title: 'CTR (Click Through Rate)',
              __typename: 'Tooltip',
            },
            {
              key: null,
              message:
                'Represents Sites that are unknown and cannot be categorized into one of the other Sites listed.',
              title: 'Other',
              __typename: 'Tooltip',
            },
          ],
          noDataTooltips: null,
          overrides: null,
          fallbackTable: null,
          feedSource: null,
        },
        {
          cid: 'displayPerformanceByTacticTable',
          id: 'displayPerformanceByTacticTable',
          dataChecks: ['HasDisplay'],
          disabled: null,
          dataSource: 'DISPLAY.ByTacticImpression',
          breakpoints: ['lg6', 'sm12'],
          name: 'genericTable',
          title: 'Performance By Tactic',
          __typename: 'genericTable',
          columns: ['Tactic', 'Impressions', 'ImpressionPercent', 'Clicks', 'ClickThrough', 'Conversions'],
          commonTweaks: null,
          dataChecksByCampaign: null,
          downloadTableData: 'csv',
          exportableTableData: 'xls',
          filterBy: null,
          footer: false,
          hasProgressBars: null,
          hideIfNoData: null,
          icon: null,
          primaryStar: null,
          searchEnabled: null,
          sortBy: 'Impressions',
          useTacticSummaryData: null,
          widths: null,
          availableColumns: ['Tactic', 'Impressions', 'ImpressionPercent', 'Clicks', 'ClickThrough', 'Conversions'],
          itemLimit: null,
          headers: null,
          iconOverride: null,
          tooltips: [
            {
              key: null,
              message:
                'Percentage of times a user clicked on an ad (clicks/impressions). For Display, the industry average is 0.05% to 0.10%. For Preroll, industry average is 0.10% to 0.30%.',
              title: 'CTR (Click Through Rate)',
              __typename: 'Tooltip',
            },
            {
              key: null,
              message:
                'Percentage of the Total Impressions. It is calculated as Impressions divided by Total Impressions. Same as “% of Total”.',
              title: 'Impressions %',
              __typename: 'Tooltip',
            },
            {
              key: null,
              message:
                'Uses a highly curated list of sites that index high with the desired audience. Examples: Mom network, Sports, Arts & Entertainment, Political and others.',
              title: 'Managed Networks',
              __typename: 'Tooltip',
            },
            {
              key: null,
              message:
                'Serves ads utilizing 3rd party data sources to target specific audiences by behavior, demographic, interest and intent. Examples: Gender, Household Income, Health Enthusiasts, Parents and others.',
              title: 'Audience Targeting',
              __typename: 'Tooltip',
            },
            {
              key: null,
              message:
                'Ads are delivered to your most valuable customers and prospects after they visit your website. Less than 2% of consumers convert on a first-time visit. Conversions can go up as much as 300% with retargeting.',
              title: 'Retargeting',
              __typename: 'Tooltip',
            },
          ],
          noDataTooltips: null,
          overrides: null,
          fallbackTable: null,
          feedSource: null,
        },
        {
          cid: 'displayDailyPerformanceLineChart',
          id: 'displayDailyPerformanceLineChart',
          dataChecks: ['HasDisplay'],
          disabled: null,
          dataSource: 'DISPLAY.ByDateImpression',
          breakpoints: ['lg6', 'sm12'],
          name: 'genericLineChart',
          title: 'Daily Performance',
          __typename: 'genericLineChart',
          chartColorIndex: null,
          chartType: null,
          commonTweaks: ['datebackfill'],
          dataKey: 'Date',
          exportableTableData: 'xls',
          hideEmptySeriesKeys: null,
          hideIfNoData: null,
          hideLegend: null,
          availableLineSeriesKeys: ['Impressions', 'Clicks', 'ClickThrough'],
          lineSeriesKeys: ['Impressions', 'Clicks', 'ClickThrough'],
          showyAxisSplitLine: null,
          total: null,
          useTacticSummaryData: null,
          xAxisIntervalCount: 7,
          xAxisMaxItems: null,
          xAxisShowAll: null,
          xAxisSplitLineHide: null,
          yAxisHide: null,
          yAxisLineHide: null,
          xAxisRotateDeg: null,
          showOneLine: null,
          chartColors: null,
          sideTotalMetrics: null,
          tooltips: null,
          noDataTooltips: null,
          overrides: null,
          feedSource: null,
        },
        {
          cid: 'displayPerformanceByZipCodeTable',
          id: 'displayPerformanceByZipCodeTable',
          dataChecks: ['HasDisplay'],
          disabled: null,
          dataSource: 'DISPLAY.ByZipImpression',
          breakpoints: ['lg6', 'sm12'],
          name: 'genericTable',
          title: 'Performance By Zip Code',
          __typename: 'genericTable',
          columns: ['Zipcode', 'Impressions', 'Clicks', 'ClickThrough', 'Conversions'],
          commonTweaks: null,
          dataChecksByCampaign: null,
          downloadTableData: 'csv',
          exportableTableData: 'xls',
          filterBy: null,
          footer: null,
          hasProgressBars: null,
          hideIfNoData: false,
          icon: null,
          primaryStar: null,
          searchEnabled: true,
          sortBy: 'Impressions',
          useTacticSummaryData: null,
          widths: null,
          availableColumns: ['Zipcode', 'Impressions', 'Clicks', 'ClickThrough', 'Conversions'],
          itemLimit: null,
          headers: null,
          iconOverride: null,
          tooltips: [
            {
              key: null,
              message:
                'Percentage of times a user clicked on an ad (clicks/impressions). For Display, the industry average is 0.05% to 0.10%. For Preroll, industry average is 0.10% to 0.30%.',
              title: 'CTR (Click Through Rate)',
              __typename: 'Tooltip',
            },
          ],
          noDataTooltips: null,
          overrides: null,
          fallbackTable: null,
          feedSource: null,
        },
        {
          cid: 'displayPerformanceByGeoTable',
          id: 'displayPerformanceByGeoTable',
          dataChecks: ['HasDisplay'],
          disabled: null,
          dataSource: 'DISPLAY.GeoPerformanceImpression',
          breakpoints: ['lg12', 'sm12'],
          name: 'genericTable',
          title: 'Performance By Geo',
          __typename: 'genericTable',
          columns: ['CityId', 'Impressions', 'Clicks', 'ClickThrough', 'Conversions'],
          commonTweaks: null,
          dataChecksByCampaign: null,
          downloadTableData: 'csv',
          exportableTableData: 'xls',
          filterBy: null,
          footer: null,
          hasProgressBars: null,
          hideIfNoData: null,
          icon: null,
          primaryStar: null,
          searchEnabled: true,
          sortBy: 'Impressions',
          useTacticSummaryData: null,
          widths: null,
          availableColumns: ['CityId', 'Impressions', 'Clicks', 'ClickThrough', 'Conversions'],
          itemLimit: null,
          headers: null,
          iconOverride: null,
          tooltips: [
            {
              key: null,
              message:
                'Percentage of times a user clicked on an ad (clicks/impressions). For Display, the industry average is 0.05% to 0.10%. For Preroll, industry average is 0.10% to 0.30%.',
              title: 'CTR (Click Through Rate)',
              __typename: 'Tooltip',
            },
          ],
          noDataTooltips: null,
          overrides: null,
          fallbackTable: null,
          feedSource: null,
        },
        {
          cid: 'displayGeoFencingLocationsTable',
          id: 'displayGeoFencingLocationsTable',
          dataChecks: null,
          disabled: null,
          dataSource: 'DISPLAY.ByGeoFenceImpression',
          breakpoints: ['lg12', 'sm12'],
          name: 'genericTable',
          title: 'Geofencing Locations',
          __typename: 'genericTable',
          columns: ['GeoFenceName', 'Impressions', 'Clicks', 'ClickThrough'],
          commonTweaks: null,
          dataChecksByCampaign: ['HasDisplay', 'HasDISPLAYGeoFence'],
          downloadTableData: 'csv',
          exportableTableData: 'xls',
          filterBy: null,
          footer: null,
          hasProgressBars: null,
          hideIfNoData: true,
          icon: null,
          primaryStar: null,
          searchEnabled: true,
          sortBy: 'Impressions',
          useTacticSummaryData: null,
          widths: null,
          availableColumns: ['GeoFenceName', 'Impressions', 'Clicks', 'ClickThrough'],
          itemLimit: null,
          headers: null,
          iconOverride: null,
          tooltips: [
            {
              key: null,
              message:
                'Percentage of times a user clicked on an ad (clicks/impressions). For Display, the industry average is 0.05% to 0.10%. For Preroll, industry average is 0.10% to 0.30%.',
              title: 'CTR (Click Through Rate)',
              __typename: 'Tooltip',
            },
          ],
          noDataTooltips: null,
          overrides: null,
          fallbackTable: null,
          feedSource: null,
        },
        {
          cid: 'displayCampaignTargeting',
          id: 'displayCampaignTargeting',
          dataChecks: ['HasDisplay'],
          disabled: null,
          dataSource: 'DISPLAYTotal',
          breakpoints: ['lg6', 'sm12'],
          name: 'targetingList',
          title: 'Campaign Targeting',
          __typename: 'targetingList',
          hideIfNoData: true,
          exportableTableData: 'xls',
          downloadTableData: 'csv',
          tooltips: [
            {
              key: null,
              message:
                'This module shows the locations where the campaign is targeted to and not where impressions were actually served. Where impressions were served are shown in the other modules on this page.',
              title: 'Campaign Targeting',
              __typename: 'Tooltip',
            },
          ],
          overrides: null,
        },
      ],
      __typename: 'SectionWrapper',
    },
  ],
};

export const isExporting = false;

export const mappingTacticsNames = {
  AUDIO: 'Audio',
  BROADCAST: 'Broadcast TV',
  BROADSTREET: 'Broadstreet',
  CALLTRACKING: 'Call Tracking',
  DISPLAY: 'Display',
  FBINSIGHTS: 'Facebook Insights',
  GA: 'Google Analytics',
  GAMDISPLAY: 'Display - O&O',
  GAMVIDEO: 'Video - O&O',
  GOOGLESEARCH: 'Google Search Console',
  GOOGLEVIDEO: 'YouTube',
  OTT: 'OTT/CTV',
  PREROLL: 'Video',
  SEM: 'SEM',
  SIMPGEOFENCE: 'Geofence',
  SITEIMPACT: 'Email Marketing',
  SOCIAL: 'Facebook',
  VIDEO: 'CDV',
  GTDISPLAY: 'Geofence - Display',
  GTVIDEO: 'Geofence - Video',
  LINEAR: 'Sinclair RSN',
  TRUGEOFENCE: 'True Geo',
  TV2OTT: 'InnovidXP OTT',
  TV2LINEAR: 'InnovidXP Linear',
  TV2CROSSPLATFORM: 'InnovidXP Cross-Platform',
  GEOFENCE: 'Geofence',
};
