
import Vue from 'vue';

export default Vue.extend({
  name: 'ImpressionsRow',
  props: ['item', 'dateRange', 'selectedRange'],
  computed: {
    showPrevPeriod(): boolean {
      return this.dateRange !== 'alltime';
    },
    previousProgress(): number {
      const percentage = Math.floor((this.item?.prevImpressions / this.item?.impressionsGoal) * 100);
      return percentage;
    },
  },
  methods: {
    numberFormat(value) {
      return new Intl.NumberFormat().format(value);
    },
  },
});
