export const componentConfig = {
  cid: 'orderlistPerformanceSmallChart',
  id: 'orderlistPerformanceSmallChart',
  dataChecks: ['HasDisplay'],
  disabled: null,
  dataSource: 'ORDERLIST.smallChartMetrics',
  breakpoints: ['lg3', 'sm12'],
  name: 'genericLineChart',
  title: '',
  __typename: 'genericLineChart',
  chartColorIndex: null,
  chartType: null,
  commonTweaks: ['datebackfill'],
  dataKey: 'Day',
  exportableTableData: 'xls',
  hideEmptySeriesKeys: null,
  hideIfNoData: null,
  hideLegend: true,
  availableLineSeriesKeys: ['Actual', 'Before'],
  lineSeriesKeys: ['Actual', 'Before'],
  showyAxisSplitLine: null,
  total: null,
  useTacticSummaryData: null,
  xAxisIntervalCount: 1,
  xAxisMaxItems: null,
  xAxisShowAll: true,
  xAxisSplitLineHide: null,
  yAxisHide: null,
  yAxisLineHide: null,
  xAxisRotateDeg: null,
  showOneLine: null,
  chartColors: null,
  sideTotalMetrics: null,
  tooltips: [
    {
      key: 'all',
      tooltip:
        'This module shows the number of live Orders plus Orders that are not yet live. It does not include Orders that have already ended.',
    },
    {
      key: 'active',
      tooltip: 'Click to show only Orders that have started and are live.',
    },
    {
      key: 'pending',
      tooltip: 'Click to show only Orders that have not yet started. This feature is coming soon.',
    },
    {
      key: 'endingSoon',
      tooltip: 'Click to show only Orders that are ending within the next 7 days.',
    },
  ],
  overrides: null,
  hideTitle: null,
  noDataTooltips: ['no data'],
  dateRange: 'thismonth',
  fetchData: { method: 'getOrderInfo' },
};

export const componentHeight = '120px';
