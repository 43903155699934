
import Vue from 'vue';
import fileBasedLayout from './filebased.vue';
import { AdPerformance } from '../../store/modules/customer/types';
import { Campaign } from '../../types/filters';
import { LayoutSection, ValidComponents } from '../../types/layout';
import AppGlobals from '../global/globals.vue';
import Status from '../global/status.vue';
import utils from '../../util';
import validateComponent, { validateComponentProps } from '../../lib/home/validateComponent';
import statusCheck from '../../mixins/statusCheck';
import analytics from '../../mixins/analytics';
import ToolbarC360 from '../components/toolbar/toolbarC360.vue';
import FooterC360 from '../global/footerC360.vue';
import AdvertiserBarC360 from '../components/toolbar/advertiserBarC360.vue';
import { C360Provider, defineAbilityFor } from '@c360/ui';

const debugMissingData = false;

export default Vue.extend({
  name: 'Share',
  components: {
    MainFooter: () => import('../global/footer.vue'),
    fileBasedLayout,
    AppGlobals,
    Status,
    ToolbarC360,
    FooterC360,
    AdvertiserBarC360,
    C360Provider,
  },
  mixins: [statusCheck, analytics],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: (): any => ({
    expanded: true,
    rightDrawer: false,
    snackbar: {
      show: false,
      text: '',
      color: '',
    },
    error: '',
    success: '',
    loading: true,
    currentSection: null,
    isCustomRange: false,
    validSectionsCache: {},
    refreshToken: '',
    accessToken: '',
  }),
  computed: {
    ability() {
      return defineAbilityFor({
        isSuperUser: false,
        isAgencyAdmin: false,
        products: [],
        permissions: ['HIDE_HELP_CENTER'],
        activeAgencyName: '',
        tenantsCount: 1,
      });
    },
    c360Path(): string {
      if (this.advertiserSelected) {
        return `${window.location.protocol}//${window.location.host}/home?id=${this.advertiserSelected}&tab=${this.currentSection?.id}&viewCampaigns=${this.$route.query.viewCampaigns}`;
      } else {
        return `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`;
      }
    },
    layoutSections() {
      return [this.$store.state.customer?.sharedSelection?.layout?.layoutCustomizations.config];
    },
    layoutName(): string {
      return this.$store.state.customer?.sharedSelection?.layout?.layoutCustomizations.PropertyId;
    },
    isLoading(): boolean {
      if (this.$store.state.customer.adBlockDetected) {
        return false;
      }
      return (
        this.loading ||
        this.$store.state.customer.waitingForAuth ||
        this.$store.state.customer.loadingLayouts ||
        this.$store.state.customer.loadingAdPerformance ||
        this.$store.state.customer.loadingSummaryPerformance ||
        this.$store.state.customer.loadingTacticSummaryPerformance ||
        this.$store.state.advertiser?.isAdvertiserInfoLoading ||
        this.$store.state.customer.loadingShareContent
      );
    },
    canRenderContent(): boolean {
      return (
        !this.isLoading &&
        this.advertiserSelected &&
        this.adData &&
        this.adData.loaded &&
        this.$store.state.customer.dynamicLayout &&
        !this.errorMessage
      );
    },
    canExport(): boolean {
      return this.validComponents[0]?.components?.length > 0;
    },
    isLanding(): boolean {
      return !this.canRenderContent && !(this.adData && this.adData.loaded);
    },
    errorMessage(): string | null {
      if (
        this.$store.state.customer.advertisers &&
        this.$store.state.customer.advertisers.list &&
        this.$store.state.customer.advertisers.list.error
      ) {
        return `Error: ${this.$store.state.customer.advertisers.list.error}`;
      }
      if (
        this.$store.state.customer.adPerformance.error &&
        this.$store.state.customer.adPerformance.error.toLowerCase() !== 'empty'
      ) {
        return `Error: ${this.$store.state.customer.adPerformance.error}`;
      }

      if (this.currentSection?.dataChecks && this.adData && !this.loading) {
        const hasData = this.adData[this.currentSection.dataChecks];
        if (!hasData) return 'Missing campaign data';
      }

      return null;
    },
    layoutCacheKey(): string {
      // some filters trigger re-validating the avaible tabs, other do not
      const data = this.$store.state.customer.adPerformance as AdPerformance;
      const key = `${data.PRPPID}:${data.StartDate}:${data.EndDate}:${this.$store.state.customer.currentNavTab}:${this.layoutName}`;
      return key;
    },
    adData(): AdPerformance | null {
      const data = this.$store.state.customer.adPerformance as AdPerformance;
      if (!data || !data.loaded) {
        return null;
      }
      return data;
    },
    allTimeAdData(): AdPerformance | null {
      const data = this.$store.state.customer.allTimeAdPerformance as AdPerformance;
      if (!data || !data.loaded) {
        return null;
      }
      return data;
    },
    advertiserSelected(): boolean {
      return this.$store.state.customer.selection.advertiserId;
    },
    validComponents(): Array<ValidComponents> {
      if (!this.currentSection || !this.currentSection.components) {
        return [];
      }
      let componentList = [];
      try {
        componentList = this.currentSection?.components.map((components: ValidComponents): ValidComponents => {
          components?.components.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (section: any): any => {
              const props: validateComponentProps = {
                element: section,
                adData: this.adData,
                allTimeAdData: this.allTimeAdData,
                campaignAdPerformance: this.$store.state.customer?.campaignAdPerformance,
                debugMissingData,
              };
              return validateComponent(props).validInCurrentSelection;
            },
          );
          return components;
        });
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.log('validComponents', exp);
      }
      return componentList;
    },
  },
  created(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).vueApp = this;
  },
  mounted() {
    this.setCurrentSection();
    // DASH-2445, track when someone uses a shared track, with mixpanel
    this.analyticShareTrack(
      this.trackValue.EVENT_NAV,
      this.trackValue.TRACK_NAV_SHARE_DASHBOARD_USED,
      this.$route?.params?.shortId,
      this.$store.state.customer?.sharedSelection?.etoken,
      this.advertiserSelected,
      this.$store.state.customer?.sharedSelection?.tab,
    );
  },
  watch: {
    '$store.state.customer.sharedDateRanges': {
      handler(): void {
        if (this.$store.state.customer?.sharedDateRanges) this.dateChange();
      },
      immediate: true,
    },
    '$route.query.viewCampaigns': {
      handler(newCampaigns: string, oldCampaign: string): void {
        if (newCampaigns && newCampaigns !== oldCampaign) {
          const campaignListSource =
            this.$store.state.customer?.allTimeCampaigns?.campaignlist ||
            this.$store.state.customer.campaigns?.campaignlist;
          if (campaignListSource && Array.isArray(campaignListSource) && campaignListSource.length > 0) {
            const newCampaignsList: string[] = newCampaigns ? newCampaigns?.split(',') : null;
            const campaigns = campaignListSource.filter(
              (c: Campaign) =>
                newCampaignsList.includes(c.id) &&
                c.CampaignType.toLowerCase() ===
                  utils.dataKeyBySectionIdMap(this.$store.state.customer.selection.tab).toLowerCase(),
            );
            if (campaigns) {
              this.$store.dispatch('setSelectedCampaigns', campaigns);
              this.loadViewCampaigns();
              return;
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    doneLoading(): void {
      clearTimeout(this.doneLoadingTimer);
      this.doneLoadingTimer = setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    getValidSections(): LayoutSection[] {
      // const layout = this.$store.state.customer?.sharedSelection?.layout;
      if (!this.layoutSections) {
        return [];
      }

      return this.layoutSections;
    },
    setCurrentSection(id: string, retries = 10): Promise<void> {
      let sectionId = (id || '').toLowerCase();
      // const layout = this.$store.state.customer.dynamicLayout;
      // const layout = this.$store.state.customer?.sharedSelection?.layout;
      const validSections = this.getValidSections(!this.lastValidSections || this.lastValidSections.length === 0);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { dmas, campaigns, types, viewCampaigns, tab, summaryView, ...cleanQuery } =
        this.$store.state.customer.selection;
      const isFiltering = !!(types || campaigns);
      // NOTE: when filters/date ranges change, which sections are valid may change
      // we're letting it be 'no data' if we're filtering by types though, it could be that we unselected all

      if ((!validSections || validSections.length === 0) && !isFiltering) {
        if (retries > 0) {
          const delayed = t => new Promise(resolve => setTimeout(resolve, t));
          return delayed(250).then(() => {
            return this.setCurrentSection(id, retries - 1);
          });
        }
        return new Promise(() => {
          this.doneLoading();
          // reject(new Error('no valid section'));
        });
      }
      if (!validSections || validSections.length === 0) {
        this.doneLoading();
        return new Promise(resolve => {
          resolve();
        });
      }
      // no id means its being called from a watch
      if (!sectionId) {
        if (!this.currentSection) {
          // default (on load or new advertiser)
          if (tab) {
            // handle use case where there is an active tab in the url
            // try to find a corresponding section or use the first one
            const matchingSection = validSections.find(section => section.id === tab);
            if (matchingSection) this.currentSection = matchingSection;
            else {
              [this.currentSection] = validSections;
            }
          } else {
            [this.currentSection] = validSections;
          }
          sectionId = this.currentSection.id;
        } else {
          // if we are filtering by campaign types, assume the current tab/section is right
          if (tab && isFiltering) {
            this.currentSection = this.layoutSections.find((section: LayoutSection) => section.id === tab);
          } else {
            const validSection = validSections.find((section: LayoutSection) => section.id === tab);
            if (validSection) {
              this.currentSection = validSection;
            } else {
              [this.currentSection] = validSections;
            }
          }
          sectionId = this.currentSection.id;
        }
      } else {
        // change coming from tab btn
        if (this.currentSection && sectionId === this.currentSection.id) {
          return new Promise(resolve => {
            resolve();
          });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.currentSection = validSections.find((section: any) => section.id === sectionId);
      }

      return this.$store.dispatch('setCurrentSection', this.currentSection).then(() => {
        cleanQuery.tab = sectionId;
        this.doneLoading();
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validateComponent(element: any): any {
      let isSelectionValid = true;
      let isAllTimeValid = true;
      const elementName = element.id || element.name;
      if (typeof element.dataChecks === 'string') {
        if (!this.checkFlag(this.adData, element.dataChecks) && !element.showEvenIfNoData) {
          if (debugMissingData) {
            // eslint-disable-next-line no-console
            console.info(`not rendering [${elementName}] due to [${element.dataChecks}]`, element, this.adData);
          }
          isSelectionValid = false;
        }
        if (!this.checkFlag(this.allTimeAdData, element.dataChecks) && !element.showEvenIfNoData) {
          isAllTimeValid = false;
        }
        return { validInCurrentSelection: isSelectionValid, validAllTime: isAllTimeValid };
      }
      if (Array.isArray(element.dataChecks)) {
        if (
          element.dataChecks.find((test: string) => {
            if (!this.checkFlag(this.adData, test) && !element.showEvenIfNoData) {
              if (debugMissingData) {
                // eslint-disable-next-line no-console
                console.info(
                  `not rendering [${elementName}] due to [${element.dataChecks}][${test}]`,
                  element,
                  this.adData,
                );
              }
              return true;
            }
            return false;
          })
        ) {
          isSelectionValid = false;
        }
        if (
          element.dataChecks.find((test: string) => {
            return !this.checkFlag(this.allTimeAdData, test) && !element.showEvenIfNoData;
          })
        ) {
          isAllTimeValid = false;
        }
        return { validInCurrentSelection: isSelectionValid, validAllTime: isAllTimeValid };
      }
      if (element.dataChecks) {
        // eslint-disable-next-line no-console
        console.info(`not rendering [${elementName}] due to unhandled dataChecks`, element, this.adData);
        return { validInCurrentSelection: false, validAllTime: false };
      }
      if (typeof element.dataChecksAny === 'string') {
        if (!this.checkFlag(this.adData, element.dataChecksAny)) {
          if (debugMissingData) {
            // eslint-disable-next-line no-console
            console.info(`not rendering [${elementName}] due to [${element.dataChecksAny}]`, element, this.adData);
          }
          isSelectionValid = false;
        }
        if (!this.checkFlag(this.allTimeAdData, element.dataChecksAny)) {
          isAllTimeValid = false;
        }
        return { validInCurrentSelection: isSelectionValid, validAllTime: isAllTimeValid };
      }
      if (Array.isArray(element.dataChecksAny)) {
        isSelectionValid = false;
        if (element.dataChecksAny.find((test: string) => this.checkFlag(this.adData, test))) {
          isSelectionValid = true;
        }
        isAllTimeValid = false;
        if (element.dataChecksAny.find((test: string) => this.checkFlag(this.allTimeAdData, test))) {
          isAllTimeValid = true;
        }
        if (!isSelectionValid && debugMissingData) {
          // eslint-disable-next-line no-console
          console.info(`not rendering [${elementName}] due to any [${element.dataChecksAny}]`, element, this.adData);
        }
        return { validInCurrentSelection: isSelectionValid, validAllTime: isAllTimeValid };
      }
      if (element.dataChecksAny) {
        // eslint-disable-next-line no-console
        console.info(`not rendering [${elementName}] due to unhandled dataChecksAny`, element, this.adData);
        return { validInCurrentSelection: false, validAllTime: false };
      }
      return { validInCurrentSelection: true, validAllTime: true };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    checkFlag(source: any, flag: string): boolean {
      return !!utils.propertyByPath(source, flag);
    },
    generateCache(): void {
      this.$store.dispatch('generateCache');
    },
    async dateChange(): Promise<void> {
      // we only want data for the currently selected capaigns.
      const selectedCampaigns =
        this.$store.state.filters?.selectedCampaigns && this.$store.state.filters.selectedCampaigns.length
          ? this.$store.state.filters.selectedCampaigns.map(c => c.id).join(',')
          : null;

      await this.$store
        .dispatch('admin/getShareDashboardLink', {
          email: null,
          agencyPartner: this.$store.state.customer.sharedSelection?.agencyPartner,
          etoken: this.$store.state.customer?.sharedSelection?.etoken,
          aid: this.$store.state.customer?.sharedSelection?.aid,
          layout: this.layoutName,
          // FIXME: check if that's still needed after redirects
          theme: this.Theme.companyId,
          tab: this.$store.state.customer?.sharedSelection?.tab,
          advertiserName: this.$store.state.customer?.sharedSelection?.advertiserName,
          advertiserLogo: this.$store.state.customer?.sharedSelection?.advertiserLogo,
          campaigns: this.$store.state.customer?.sharedSelection?.campaigns,
          dmas: this.$store.state.customer?.sharedSelection?.dmas,
          types: this.$store.state.customer?.sharedSelection?.types,
          viewCampaigns: this.$store.state.customer?.sharedSelection.viewCampaigns,
          selectedCampaigns,
          daterange:
            this.$store.state.customer?.sharedDateRanges?.daterange ||
            this.$store.state.customer?.sharedSelection?.daterange,
          startdate:
            this.$store.state.customer?.sharedDateRanges?.startdate ||
            this.$store.state.customer?.sharedSelection?.startdate,
          enddate:
            this.$store.state.customer?.sharedDateRanges?.enddate ||
            this.$store.state.customer?.sharedSelection?.enddate,
          campaigndaterange:
            this.$store.state.customer?.sharedDateRanges?.campaigndaterange === 'customRange' ? '' : 'alltime',
          campaignstartdate: this.$store.state.customer?.sharedDateRanges?.campaignstartdate,
          campaignenddate: this.$store.state.customer?.sharedDateRanges?.campaignenddate,
          allDateAccess: this.$store.state.customer?.sharedSelection?.allDateAccess,
          baseDate: this.$store.state.customer?.sharedSelection?.baseDate,
          summaryView: this.$store.state.customer?.sharedSelection?.summaryView,
          shareWithDates: this.$store.state.customer?.sharedSelection?.shareWithDates,
          aliases: this.$store?.state?.advertiser?.advertiserInfo?.data?.aliases,
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((tokenResponse: any) => {
          if (!tokenResponse || !tokenResponse.token) {
            this.error = 'Sorry, an error occurred';
            // eslint-disable-next-line no-console
            console.error('getShareDashboardLink error', tokenResponse);
          }
          if (tokenResponse.id) {
            window.location.href = `${window.location.origin}/s/${tokenResponse.id}`;
          } else {
            window.location.href = `${window.location.origin}/view/?t=${tokenResponse.token}`;
          }
          return;
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          this.error = 'Sorry, an error occurred';
          // eslint-disable-next-line no-console
          console.error('dateChange getShareDashboardLink error', err);
        });
    },
    loadViewCampaigns(): void {
      if (
        !this.$store.state.filters.selectedCampaigns ||
        !this.$store.state.filters.selectedCampaigns.length ||
        !this.$store.state.customer.selection.advertiserId
      ) {
        return;
      }

      function getIdFromCampaign(allCampaigns, campaign) {
        allCampaigns.push(campaign.id);
        return allCampaigns;
      }
      const params = { ...this.$store.state.customer.selection };
      // if campaign dates set campagin date range to customRange.
      const campaignDateRange =
        params.campaignenddate && params.campaignstartdate ? 'customRange' : params.campaigndaterange;
      this.$store
        .dispatch('getCampaignAdPerformance', {
          advertiserId: params.advertiserId,
          daterange: params.daterange,
          startdate: params.startdate,
          enddate: params.enddate,
          campaigndaterange: campaignDateRange,
          campaignstartdate: params.campaignstartdate,
          campaignenddate: params.campaignenddate,
          creative: params.creative,
          campaigns: this.$store.state.filters.selectedCampaigns?.reduce(getIdFromCampaign, []).join(','),
          token: this.$store.state.customer?.sharedSelection?.etoken,
          currentNavTab: this.$store.state.customer?.selection?.tab,
        })
        .catch((error: Error) => {
          this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error(error);
        });
    },
  },
});
