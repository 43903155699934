
import Vue from 'vue';
import { parse as parseDate, format, isValid, isAfter, addDays } from 'date-fns';
import DateRange from '../../../../types/dateRange';
import utils from '../../../../util';
import 'vuetify-daterange-picker/dist/vuetify-daterange-picker.css';
import parseISO from 'date-fns/fp/parseISO';
import _unionBy from 'lodash.unionby';

export default Vue.extend({
  name: 'dateRangeDropdown',
  inheritAttrs: false,
  props: ['endDate', 'startDate', 'rangeLimits', 'dateRanges', 'datesWithData', 'campaignAbsoluteDates'],
  components: {},
  data(): {
    showMenu: boolean;
    localStartDate: string | null;
    localEndDate: string | null;
    pickerFromExpanded: boolean;
    pickerToExpanded: boolean;
    customRangeSelected: boolean;
    editingCustomRangeFrom: boolean;
    editingCustomRangeTo: boolean;
    customRangeFromInputValue: string;
    customRangeToInputValue: string;
    dateFromLiveFeedbackTimer: number;
    dateToLiveFeedbackTimer: number;
    customRangeFromValidated: boolean;
    customRangeToValidated: boolean;
    customRangeValidationError: string | null;
  } {
    return {
      localStartDate: null,
      localEndDate: null,
      showMenu: false,
      pickerFromExpanded: false,
      pickerToExpanded: false,
      customRangeSelected: false,
      editingCustomRangeFrom: false,
      editingCustomRangeTo: false,
      customRangeFromInputValue: '',
      customRangeToInputValue: '',
      dateFromLiveFeedbackTimer: 0,
      dateToLiveFeedbackTimer: 0,
      customRangeFromValidated: true,
      customRangeToValidated: true,
      customRangeValidationError: null,
    };
  },
  created(): void {
    this.localStartDate = this.formatLongDate(utils.normalizeDate(this.startDate));
    this.localEndDate = this.formatLongDate(utils.normalizeDate(this.endDate));
    const { campaigndaterange } = this.$route.query;

    if (this.isShared) {
      this.customRangeSelected = this.$store.state.customer.sharedSelection.daterange === 'customRange';
    } else {
      this.customRangeSelected = campaigndaterange === 'customRange';
    }
  },
  computed: {
    sharedPageWithDates(): boolean {
      if (!this.isShared) return true;
      return this.$store.state.customer?.sharedSelection?.shareWithDates;
    },
    rangeLimitsNormalized(): Array<string> {
      if (!this.rangeLimits) return [];
      const start = format(utils.normalizeDate(this.rangeLimits[0]), 'yyyy-MM-dd');
      const end = format(utils.normalizeDate(this.rangeLimits[1]), 'yyyy-MM-dd');
      return [start, end];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pickerRanges(): any {
      /* rules
        use alltime start and end date as min and max dates in range
        if campaign's not ended use current date as end date
        start and end dates can be the same
        start date can't be after end date
        end date can't be before start date
        no dates can be outside of global range
      */

      // DASH-3412: currently we don't have proper end date for TV2 tactic
      // TODO: review when TV2 tactic fixed

      let globalStart = utils.normalizeDate(this.rangeLimitsNormalized[0]);
      let globalEnd = utils.normalizeDate(this.rangeLimitsNormalized[1]);
      const localStart = utils.normalizeDate(this.localStartDate);

      const currentDate = new Date();

      const hasCampaignEndDate =
        this.campaignAbsoluteDates && this.campaignAbsoluteDates[1] && this.campaignAbsoluteDates[1] !== 'noEndDate';

      if (this.campaignAbsoluteDates && this.campaignAbsoluteDates[0]) {
        const campaignAbsoluteStartDate = utils.normalizeDate(this.campaignAbsoluteDates[0]);

        // DASH-4573 - get the earliest possible date
        if (globalStart > campaignAbsoluteStartDate) {
          globalStart = campaignAbsoluteStartDate;
        }
      }

      if ((hasCampaignEndDate && !utils.hasCampaignEnded(this.campaignAbsoluteDates[1])) || !hasCampaignEndDate) {
        globalEnd = currentDate;
      } else {
        const campaignAbsoluteEndDate = utils.normalizeDate(this.campaignAbsoluteDates[1]);

        // DASH-4573 - get the earliest possible date
        if (globalEnd < campaignAbsoluteEndDate) {
          globalEnd = campaignAbsoluteEndDate;
        }
      }

      return {
        from: {
          min: format(globalStart, 'yyyy-MM-dd'),
          max: format(globalEnd, 'yyyy-MM-dd'), // one day before end date
          date: globalStart,
        },
        to: {
          min: format(localStart, 'yyyy-MM-dd'), // one day after start date
          max: format(globalEnd, 'yyyy-MM-dd'),
          date: globalEnd,
        },
      };
    },
    dateRangeList(): DateRange[] | null {
      const adPerformanceCampaignlist = this.$store.state.customer?.adPerformance?.campaignlist || [];
      const campaignAdPerformanceCampaignlist = this.$store.state.customer?.campaignAdPerformance?.CampaignList || [];

      const campaignListMap = new Map();
      adPerformanceCampaignlist.forEach(c => campaignListMap.set(c.CampaignId, c));
      campaignAdPerformanceCampaignlist.forEach(c => campaignListMap.set(c.CampaignId, c));
      const campaignList = Array.from(campaignListMap.values());

      const campIds = this.$route.query?.viewCampaigns?.split(',');
      let list: DateRange[] | null = null;
      //  multiple selected
      if (campIds?.length > 1 || !!this.$route.query.viewCampaignType) {
        let dateRangeArrs;
        // get date range arrays for each campaign
        if (this.$route.query.viewCampaignType) {
          dateRangeArrs = campaignList?.map(c => c.AvailableDateranges);
        } else {
          dateRangeArrs = campIds.reduce((rangeArrs: DateRange[][], campId: string) => {
            const found = campaignList?.find(c => c.CampaignId === campId);
            if (found) rangeArrs.push(found.AvailableDateranges);
            return rangeArrs;
          }, []);
        }
        // union the arrays by range key
        list = _unionBy(...dateRangeArrs, 'RangeKey');
      } else {
        // single selected
        const currCampId = this.$route.query.viewCampaigns;
        const found = campaignList?.find(c => c.CampaignId === currCampId);

        if (found && found.AvailableDateranges) {
          list = found.AvailableDateranges;
        }
      }

      return list;
    },
    formattedStartDate(): string {
      return this.formatShortDate(this.localStartDate);
    },
    formattedEndDate(): string {
      return this.formatShortDate(this.localEndDate);
    },
    currRange(): DateRange | null {
      let range;
      let dateRangeSelected = this.$route.query.daterange;

      if (this.isShared) {
        dateRangeSelected = this.$store.state.customer.sharedSelection.daterange;
      }

      if (this.$route.query.campaigndaterange) {
        dateRangeSelected = this.$route.query.campaigndaterange;
      }

      if (!dateRangeSelected || dateRangeSelected === 'alltime') {
        range = { RangeKey: 'alltime', RangeName: 'All Time' };
      } else if (dateRangeSelected === 'customRange' && this.customRangeSelected) {
        range = { RangeKey: 'customrange', RangeName: 'Custom Range' };
      } else if (dateRangeSelected) {
        if (this.dateRangeList) {
          const found = this.dateRangeList.find(d => d.RangeKey === dateRangeSelected);
          if (found) range = found;
        }
        if (this.datesWithData) {
          const found = this.datesWithData.find(d => d.RangeKey === dateRangeSelected);
          if (found) range = found;
        }
      } else {
        return null;
      }
      return range;
    },
    hasGlobalShareDates(): boolean {
      return (
        this.$store.state.customer?.sharedSelection?.allDateAccess ||
        (this.$store.state.customer?.sharedSelection?.startdate && this.$store.state.customer?.sharedSelection?.enddate)
      );
    },
    hasCampaignShareDates(): boolean {
      return (
        (this.$store.state.customer?.sharedSelection?.campaigndaterange === 'alltime' ||
          this.$store.state.customer?.sharedSelection?.campaignstartdate) &&
        this.$store.state.customer?.sharedSelection?.tab !== 'summary'
      );
    },
    isSharedDates(): boolean {
      // Show date picker when custom dates are selected and the theme config file has shareDate as true
      if ((this.hasGlobalShareDates || this.hasCampaignShareDates) && this.Theme?.config?.shareDate) return false;
      return this.$store.state.customer?.sharedSelection?.aid;
    },
    showDatePicker(): boolean {
      return !this.isSharedDates && this.startDate && this.endDate;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isShared(): boolean {
      return this.$store.state.customer.sharedDashboard;
    },
    hasRangeLimits(): boolean {
      return this.rangeLimits?.length > 0;
    },
    summaryPage(): boolean {
      return this.$route?.query?.tab === 'summary';
    },
  },
  watch: {
    startDate: {
      handler(value): void {
        // map passed prop to bindable value
        this.localStartDate = this.formatLongDate(utils.normalizeDate(value));
        if (isAfter(parseISO(this.localStartDate), parseISO(this.localEndDate)))
          this.localEndDate = this.localStartDate;
      },
    },
    endDate: {
      handler(value): void {
        // map passed prop to bindable value
        this.localEndDate = value;
      },
    },
    localStartDate: {
      handler(value): void {
        // to pass to the export service
        const exportState = this.$store.getters.exportState || {};
        exportState.localStartDate = value;
        this.$store.dispatch('setExportState', exportState);
      },
    },
    localEndDate: {
      handler(value): void {
        // to pass to the export service
        const exportState = this.$store.getters.exportState || {};
        exportState.localEndDate = value;
        this.$store.dispatch('setExportState', exportState);
      },
    },
    'currRange.RangeKey': {
      immediate: true,
      handler(value): void {
        // to pass to the export service
        const exportState = this.$store.getters.exportState || {};
        exportState.localRange = value;
        this.$store.dispatch('setExportState', exportState);
      },
    },
    // '$store.state.customer.dateRanges': {
    //   handler(): void {
    //     setTimeout(this.setDefaultsForCustomRange, 200);
    //   },
    //   immediate: false,
    // },
  },
  methods: {
    formatShortDate(date: string | object): string {
      if (!date) {
        return '';
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let fnsDate: any;

      try {
        // date may come as a string or an already normalized date object
        if (typeof date === 'string') fnsDate = utils.normalizeDate(date);
        else fnsDate = date;

        return format(fnsDate, 'MMM d, yyy');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('formattedDataFrom', date, err);
      }
      return '';
    },
    customRangeFromClick(): void {
      this.customRangeFromValidated = true;
      this.customRangeToValidated = true;
      this.customRangeValidationError = null;
      this.editingCustomRangeFrom = true;
      this.customRangeFromInputValue = format(utils.normalizeDate(this.localStartDate), 'M/d/yy');
      this.selectCustomRange('from');
      setTimeout(() => {
        this.$refs.customRangeFromInput.focus();
      }, 250);
    },
    customRangeFromCancel(): void {
      this.customRangeFromValidated = true;
      this.customRangeToValidated = true;
      this.customRangeValidationError = null;
      this.editingCustomRangeFrom = false;
    },
    customRangeFromLiveFeedback(): void {
      if (!this.editingCustomRangeFrom) {
        // coming from ESC
        return;
      }
      this.customRangeFromValidated = false;
      this.customRangeValidationError = null;
      try {
        const tmp = parseDate(this.customRangeFromInputValue, 'M/d/yy', new Date());
        if (!tmp || !isValid(tmp)) {
          this.customRangeValidationError = 'Invalid date format';
          return;
        }
        if (tmp < this.pickerRanges.from.date) {
          this.customRangeValidationError =
            'Data not available before ' + format(this.pickerRanges.from.date, 'M/d/yy');
          return;
        }
        if (tmp > this.pickerRanges.to.date) {
          this.customRangeValidationError = 'Data not available after ' + format(this.pickerRanges.to.date, 'M/d/yy');
          return;
        }
        this.customRangeFromValidated = true;
      } catch {
        this.customRangeValidationError = 'Invalid date format';
      }
    },
    debounceCustomRangeFromLiveFeedback(): void {
      if (this.dateFromLiveFeedbackTimer !== 0) {
        clearTimeout(this.dateFromLiveFeedbackTimer);
      }
      this.dateFromLiveFeedbackTimer = setTimeout(() => {
        this.customRangeFromLiveFeedback();
      }, 100);
    },
    customRangeToLiveFeedback(): void {
      if (!this.editingCustomRangeTo) {
        // coming from ESC
        return;
      }
      this.customRangeToValidated = false;
      this.customRangeValidationError = null;
      try {
        const tmp = parseDate(this.customRangeToInputValue, 'M/d/yy', new Date());
        if (!tmp || !isValid(tmp)) {
          this.customRangeValidationError = 'Invalid date format';
          return;
        }
        if (tmp < this.pickerRanges.from.date) {
          this.customRangeValidationError =
            'Data not available before ' + format(this.pickerRanges.from.date, 'M/d/yy');
          return;
        }
        if (tmp > this.pickerRanges.to.date) {
          this.customRangeValidationError = 'Data not available after ' + format(this.pickerRanges.to.date, 'M/d/yy');
          return;
        }
        this.customRangeToValidated = true;
      } catch {
        this.customRangeValidationError = 'Invalid date format';
      }
    },
    debounceCustomRangeToLiveFeedback(): void {
      if (this.dateToLiveFeedbackTimer !== 0) {
        clearTimeout(this.dateToLiveFeedbackTimer);
      }
      this.dateToLiveFeedbackTimer = setTimeout(() => {
        this.customRangeToLiveFeedback();
      }, 100);
    },
    customRangeFromValidate(): void {
      this.customRangeFromValidated = true;
      this.customRangeToValidated = true;
      this.customRangeValidationError = null;
      if (!this.editingCustomRangeFrom) {
        // coming from ESC
        return;
      }
      this.editingCustomRangeFrom = false;
      try {
        const tmp = parseDate(this.customRangeFromInputValue, 'M/d/yy', new Date());
        if (tmp) {
          if (tmp >= this.pickerRanges.from.date && tmp <= this.pickerRanges.to.date) {
            this.localStartDate = this.formatLongDate(tmp);
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('failed to parse custom From date', this.customRangeFromInputValue);
        }
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.log('parsing custom From date', this.customRangeFromInputValue, exp.message);
      }
    },
    customRangeToClick(): void {
      this.customRangeFromValidated = true;
      this.customRangeToValidated = true;
      this.customRangeValidationError = null;
      this.editingCustomRangeTo = true;
      this.customRangeToInputValue = format(utils.normalizeDate(this.localEndDate), 'M/d/yy');
      this.selectCustomRange('to');
      setTimeout(() => {
        this.$refs.customRangeToInput.focus();
      }, 250);
    },
    customRangeToCancel(): void {
      this.customRangeFromValidated = true;
      this.customRangeToValidated = true;
      this.customRangeValidationError = null;
      this.editingCustomRangeTo = false;
    },
    customRangeToValidate(): void {
      this.customRangeFromValidated = true;
      this.customRangeToValidated = true;
      this.customRangeValidationError = null;
      if (!this.editingCustomRangeTo) {
        // coming from ESC
        return;
      }
      this.editingCustomRangeTo = false;
      try {
        const tmp = parseDate(this.customRangeToInputValue, 'M/d/yy', new Date());
        if (tmp) {
          // const globalEnd = utils.normalizeDate(this.rangeLimitsNormalized[1]);
          const globalEnd = utils.normalizeDate(this.pickerRanges.to.max);
          const localStart = utils.normalizeDate(this.localStartDate);
          if (tmp >= localStart && tmp <= globalEnd) {
            this.localEndDate = this.formatLongDate(tmp);
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('failed to parse custom From date', this.customRangeFromInputValue);
        }
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.log('parsing custom From date', this.customRangeFromInputValue, exp.message);
      }
    },
    selectCustomRange(data: string, fromNavBtn = false): void {
      this.customRangeFromValidated = true;
      this.customRangeToValidated = true;
      this.customRangeValidationError = null;

      if (fromNavBtn && !this.customRangeSelected) {
        return;
      }

      this.customRangeSelected = true;
      if (data === 'from' && !this.pickerFromExpanded) {
        this.pickerFromExpanded = !this.pickerFromExpanded;
        this.pickerToExpanded = false;
      }
      if (data === 'to' && !this.pickerToExpanded) {
        this.pickerFromExpanded = false;
        this.pickerToExpanded = !this.pickerToExpanded;
      }
    },
    closeDatePicker(data: string): void {
      this.customRangeFromValidated = true;
      this.customRangeToValidated = true;
      this.customRangeValidationError = null;
      let min: Date | null = null;
      let max: Date | null = null;

      try {
        if (this.localStartDate) {
          min = utils.normalizeDate(this.localStartDate);
        }
        if (this.localEndDate) {
          max = utils.normalizeDate(this.localEndDate);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('closeDatePicker', this.localStartDate, this.localEndDate, err);
      }

      if (!(min && min.getMonth && min.toString() !== 'Invalid Date')) {
        // eslint-disable-next-line no-console
        console.log('closeDatePicker invalid startDate', min, this.localStartDate);
        min = null;
      }

      if (!(max && max.getMonth && max.toString() !== 'Invalid Date')) {
        // eslint-disable-next-line no-console
        console.log('closeDatePicker invalid endDate', max, this.localEndDate);
        max = null;
      }
      if (min && max) {
        const invalidRange = isAfter(min, max); // returns true if date range is invalid
        if (invalidRange && data === 'from') this.localEndDate = this.localStartDate;
        else if (invalidRange && data === 'to') this.localStartDate = this.localEndDate;
      }
      if (data === 'from') {
        this.pickerFromExpanded = false;
        this.pickerToExpanded = true;
      }
    },
    applyCustomRange(): void {
      this.customRangeFromValidated = true;
      this.customRangeToValidated = true;
      this.customRangeValidationError = null;
      this.pickerFromExpanded = false;
      this.pickerToExpanded = false;
      this.showMenu = false;

      if (this.startDate !== this.localStartDate || this.endDate !== this.localEndDate) {
        // make sure the dates have actually changed
        const customRange = {
          RangeName: 'customRange',
          RangeKey: 'customRange',
          StartDate: this.localStartDate || this.startDate,
          EndDate: this.localEndDate || this.endDate,
          HasData: true,
        };
        this.$emit('select-range', customRange);
      }
    },
    selectRange(rangeKey: string, summary: boolean): void {
      this.showMenu = false;
      if (rangeKey === this.currRange?.RangeKey) return;

      this.customRangeSelected = false;
      let range;
      if (rangeKey !== 'alltime' && !summary) {
        const found = this.dateRangeList.find(d => d.RangeKey === rangeKey);
        if (found) range = found;
      } else if (rangeKey !== 'alltime' && summary) {
        const found = this.datesWithData.find(d => d.RangeKey === rangeKey);
        if (found) range = found;
      } else {
        range = { RangeKey: 'alltime', RangeName: 'All Time' };
      }
      this.$emit('select-range', range);
    },
    formatLongDate(date: Date): string {
      if (typeof date === 'undefined') return '';

      try {
        if (date && date.toString() !== 'Invalid Date') {
          return format(date, 'yyyy-MM-dd');
        } else {
          // eslint-disable-next-line no-console
          console.log('formatDate invalid', date, date);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('formatDate', date, err);
      }
      return '';
      // return format(new Date(date.replace(/-/g, '/')), 'P');
    },
    isActive(key: string): boolean {
      return !this.customRangeSelected && this.currRange?.RangeKey === key;
    },
  },
});
