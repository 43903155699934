import { parse, format } from 'date-fns';
import utils from '../../util';
import {
  TopTacticGroupings,
  OrderSummaryMetrics,
  TopTacticsGroup,
  TopTacticsWithMetrics,
  OrderSummaryMetric,
} from './orderTypes';

export const tacticIcons = {
  display: 'photo_camera_back',
  trugeofence: 'photo_camera_back',
  truegeo: 'photo_camera_back',
  preroll: 'slideshow',
  ott: 'personal_video',
  ottctv: 'personal_video',
  video: 'slideshow',
  digitalvideovideoott: 'personal_video',
  videoott: 'personal_video',
  tv2ott: 'personal_video',
  facebook: 'perm_identity',
  social: 'perm_identity',
  facebookads: 'perm_identity',
  sem: 'search',
  siteimpact: 'email',
  emailmarketing: 'email',
  broadcast: 'rss_feed',
  tv: 'rss_feed',
  gam: 'assessment',
  gamdisplay: 'assessment',
  'display-oo': 'assessment',
  'display-gam': 'assessment',
  gamvideo: 'assessment',
  'video-oo': 'assessment',
  'video-gam': 'assessment',
  googlevideo: 'personal_video',
  youtube: 'personal_video',
  sinclairrsn: 'tv',
  linear: 'tv',
  audio: 'volume_up',
  calltracking: 'phone_in_talk',
  broadstreet: 'assessment',
};

export function formatDate(date: string): string {
  try {
    if (!date) return '';
    // const parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss a', new Date());
    return format(new Date(date), 'MMM d, yyyy');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('order date', date, err);
  }
  return '';
}

export function formatDateForTopMetrics(date: string): string {
  try {
    if (!date) return '';
    return format(new Date(date), 'MMM d yy');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('formatDateForTopMetrics', date, err);
  }
  return '';
}

// this format allows us to generate groups of tables that display the associated metrics
export const topTacticGroupings: Array<TopTacticGroupings> = [
  {
    tactics: ['OTT', 'PREROLL', 'GAMVIDEO', 'VIDEO', 'GOOGLEVIDEO', 'AUDIO', 'TV2OTT'],
  },
  {
    tactics: ['DISPLAY', 'GAMDISPLAY', 'SEM', 'SOCIAL', 'TRUGEOFENCE'],
  },
  {
    tactics: ['BROADCAST'],
  },
  {
    tactics: ['SITEIMPACT'],
  },
  {
    tactics: ['LINEAR'],
  },
  {
    tactics: ['CALLTRACKING'],
  },
  {
    tactics: ['BROADSTREET'],
  },
];

// sets config info around the key metrics we care to show in the summary view
// can be expanded to include abbreviated names or other details
const metrics: OrderSummaryMetrics = new Map();
metrics.set('aired', { name: 'Times Aired', value: 'Aired' });
metrics.set('clicks', { name: 'Clicks', value: 'Clicks' });
metrics.set('clickthrough', { name: 'CTR', value: 'ClickThrough' });
metrics.set('clicktoviewrate', { name: 'Click to View Rate', value: 'ClickToViewRate' });
metrics.set('householdImpressions', { name: 'Household Imps', value: 'Impressions' });
metrics.set('impressions', { name: 'Imps All Time', value: 'Impressions' });
metrics.set('liftperspot', { name: 'Visits / Airing', value: 'LiftPerSpot' });
metrics.set('product', { name: 'Product', value: 'TacticId' });
metrics.set('vc100', { name: '100% Viewed', value: 'VC100' });
metrics.set('vcr', { name: 'VCR', value: 'VCR' });
metrics.set('visits', { name: 'Visits', value: 'Visits' });
metrics.set('views', { name: 'Views', value: 'Views' });
metrics.set('viewrate', { name: 'View Rate', value: 'ViewRate' });
metrics.set('totalprocessedcalls', { name: 'Total Calls', value: 'TotalProcessedCalls' });
metrics.set('durationavgprocessedcalls', { name: 'Avg Duration', value: 'DurationAvg_ProcessedCalls' });
metrics.set('hovers', { name: 'Hovers', value: 'Hovers' });

// each tactic has key metrics customers care to see
// this mapping associates those metrics with each tactic
export function getTopTactics(): TopTacticsGroup[] {
  return [
    // group 1
    {
      id: 'OTT',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('vc100'), metrics.get('vcr')],
    },
    {
      id: 'PREROLL',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('vc100'), metrics.get('vcr')],
    },
    {
      id: 'GAMVIDEO',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('vc100'), metrics.get('vcr')],
    },
    {
      id: 'VIDEO',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('vc100'), metrics.get('vcr')],
    },
    {
      id: 'GOOGLEVIDEO',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('vc100')],
    },
    {
      id: 'AUDIO',
      metrics: [metrics.get('product'), metrics.get('impressions')],
    },
    {
      id: 'TV2OTT',
      metrics: [metrics.get('product'), metrics.get('impressions')],
    },
    // group 2
    {
      id: 'DISPLAY',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('clicks'), metrics.get('clickthrough')],
    },
    {
      id: 'TRUGEOFENCE',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('clicks'), metrics.get('clickthrough')],
    },
    {
      id: 'GAMDISPLAY',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('clicks'), metrics.get('clickthrough')],
    },
    {
      id: 'SEM',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('clicks'), metrics.get('clickthrough')],
    },
    {
      id: 'SOCIAL',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('clicks'), metrics.get('clickthrough')],
    },
    // group 3
    {
      id: 'BROADCAST',
      metrics: [metrics.get('product'), metrics.get('aired'), metrics.get('visits'), metrics.get('liftperspot')],
    },
    // group 4
    {
      id: 'SITEIMPACT',
      metrics: [metrics.get('product'), metrics.get('views'), metrics.get('clicks'), metrics.get('clicktoviewrate')],
    },
    // group 5
    {
      id: 'LINEAR',
      metrics: [metrics.get('product'), metrics.get('householdImpressions')],
    },
    // group 6
    {
      id: 'CALLTRACKING',
      metrics: [metrics.get('product'), metrics.get('totalprocessedcalls'), metrics.get('durationavgprocessedcalls')],
    },
    // group 7
    {
      id: 'BROADSTREET',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('clicks'), metrics.get('hovers')],
    },
  ];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getTopTacticData(summaryData: any, tactic: TopTacticsGroup): TopTacticsWithMetrics | null {
  // match tactic name with summary data
  let data = summaryData[tactic.id];
  if (tactic.id.toLowerCase() === 'tv') {
    data = summaryData['BROADCAST'];
  }
  if (data) {
    const tacticData: TopTacticsWithMetrics = {
      id: tactic.id,
      slug: utils.slugify(utils.tacticTitleMap(data.TacticId)),
      metrics: [],
    };

    tacticData.metrics = tactic.metrics.map((metric: OrderSummaryMetric) => {
      const metricsData = { headerName: metric.name, dataKey: metric.value, value: null };
      if (metric.value === 'Clicks ' && !data.hasOwnProperty('Clicks')) {
        metricsData.value = 0;
      } else {
        metricsData.value = utils.formatNumberWithCommas(data[metric.value]);
      }
      return metricsData;
    });
    return tacticData;
  }
  return null;
}

export const tableHeaderRules = {
  Tactics: ['Display', 'Digital Video (Video + OTT)', 'Video', 'OTT/CTV'],
  Impressions: [
    'Display',
    'Digital Video (Video + OTT)',
    'Video',
    'OTT/CTV',
    'Facebook Ads',
    'SEM',
    'YouTube',
    'GAM',
    'Sinclair RSN',
    'Video - O&O',
    'Display - O&O',
    'Broadstreet - O&O',
    'Audio',
    'TVSquared',
    'True Geo',
  ],
  ImpressionGoal: ['Display', 'Digital Video (Video + OTT)', 'Video', 'OTT/CTV', 'Audio', 'True Geo'],
  ImpressionGoalPercent: [
    'Display',
    'Digital Video (Video + OTT)',
    'Video',
    'OTT/CTV',
    'Display - O&O',
    'Audio',
    'True Geo',
  ],
  Views: ['Email Marketing'],
  Clicks: ['Email Marketing', 'Broadstreet - O&O'],
  Hovers: ['Broadstreet - O&O'],
  ClickToViewRate: ['Email Marketing'],
  TotalProcessedCalls: ['Call Tracking'],
  DurationAvg_ProcessedCalls: ['Call Tracking'],
  all: ['FriendlyName', 'StartDate', 'EndDate'],
};

export const orderByValueMetrics = [
  'FriendlyName',
  'Tactics',
  'StartDate',
  'EndDate',
  'Impressions',
  'ImpressionGoal',
  'ImpressionGoalPercent',
  'Pacing',
  'Views',
  'Clicks',
  'ClickToViewRate',
  'TotalProcessedCalls',
  'DurationAvg_ProcessedCalls',
  'Hovers',
];
