
import Vue from 'vue';
import analytics from '../../../mixins/analytics';
import { formatDate, formatDateForTopMetrics } from '../orderUtils';
import { componentConfig, componentHeight } from './utils/configDetailsSmallLineChart';
import { OrderService } from '../../../services/order-summary';
import { C360Icon } from '@c360/ui';
import CampaignFriendlyNameEdit from '../../components/campaignNav/campaignFriendlyNameEdit.vue';
import { getRangePreviousText } from '../../components/homeController/components/utils/utils';

const orderService = new OrderService();

const orderMetricsMapping = {
  impressions: 'Impressions',
  clicks: 'Clicks',
  ctr: 'CTR',
  vcr: 'VCR',
};

export default Vue.extend({
  name: 'OrderDetailsMetrics',
  data: (): {
    selectedOrder: any;
    selectedRange: any;
    metrics: Record<string, any>[];
    loading: boolean;
    dateRange: string | null;
    activeMetric: string;
    componentHeight: string;
    showCampaignNameEdit: boolean;
    friendlyNameToDisplay: string;
    orderNameCopied: boolean;
  } => ({
    selectedOrder: null,
    selectedRange: null,
    dateRange: null,
    loading: false,
    metrics: [],
    activeMetric: 'Active + Pending Orders',
    componentHeight,
    showCampaignNameEdit: false,
    friendlyNameToDisplay: '',
    orderNameCopied: false,
  }),
  components: {
    C360Icon,
    CampaignFriendlyNameEdit,
    LineChartSmall: () => import('../../components/charts/line/lineChartSmall.vue'),
  },
  mixins: [analytics],
  created() {
    this.setDefaultDateRange();
    this.callAllMetrics();
  },
  computed: {
    formattedDaterange(): string {
      let formattedRange = '';
      switch (this.dateRange) {
        case 'thisyear':
          formattedRange = 'Last 365 Days';
          break;
        case 'thisweek':
          formattedRange = 'Last 7 Days';
          break;
        case 'thismonth':
          formattedRange = 'Last 30 Days';
          break;
      }
      return formattedRange;
    },
    isEndingSoon(): boolean {
      return parseInt(this.selectedOrder.TimeLeft.split(' ')[0]) <= 8;
    },
    singleOrderParams(): any {
      return this.$store.state.order.singleOrderParams;
    },
    isVirtualOrder(): boolean {
      return this.selectedOrder?.OrderId === '99999';
    },
    componentConfig() {
      return { ...componentConfig, lineSeriesKeys: ['On This Date', getRangePreviousText(this.selectedRange)] };
    },
    hasNoOrdersData() {
      return this.$store.state.order.hidingOrderModulesWhenNoImps;
    },
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    returnToList() {
      this.analyticTrack(
        this.trackValue.ORDERS_BACK_TO_LIST,
        `Go to Orders List from the Order "${this.selectedOrder.FriendlyName || this.selectedOrder.OrderName}"`,
      );
      this.$router.push({ query: { id: this.$route.query.id, tab: 'orderlist' } });
    },
    setDefaultDateRange() {
      this.dateRange = 'thismonth';
      this.selectedRange = 'thismonth';
    },
    async callAllMetrics() {
      this.metrics = [];
      this.getOrderMetrics();
    },

    async getOrderMetrics() {
      const method = this.componentConfig?.fetchData?.method;
      try {
        this.loading = true;
        // const fetchFunction = orderService[method];
        const fetchFunction = orderService.getSingleOrderMetrics;
        if (!fetchFunction) {
          this.error = 'Error fetching';
          console.error('Provide correct service');
          return;
        }
        const data = await fetchFunction({
          daterange: this.dateRange,
          advertiserId: this.$route.query?.id || 'N/A',
          orderId: this.$route.query?.orderId || 'N/A',
        });
        this.selectedOrder = data.order;
        this.friendlyNameToDisplay = this.selectedOrder?.FriendlyName || this.selectedOrder?.OrderName;
        for (const [key, value] of Object.entries(data.performance)) {
          this.metrics.push({
            key: key,
            title: orderMetricsMapping[key],
            percentage: value?.total?.percentage || 0,
            total: value?.total?.activeTotal || 0,
            chartData: this.splitData(value?.data, 'alltime'),
          });
        }
        return this.metrics;
      } catch (error) {
        console.error('Error fetching', error);
        return [];
      } finally {
        this.loading = false;
      }
    },
    splitData(data) {
      if (data.length % 2 !== 0) {
        data.shift();
      }
      const daysArray = [];
      const firstPartArray = [];
      const remainingPartArray = [];
      const midpoint = Math.floor(data.length / 2);
      const firstHalf = data.slice(0, midpoint);
      const secondHalf = data.slice(midpoint);
      if (this.dateRange !== 'alltime') {
        firstHalf.forEach(entry => {
          const { impressions, clicks, vcr, ctr } = entry;
          const metric = impressions || clicks || vcr || ctr || 0;
          firstPartArray.push(metric);
        });
        secondHalf.forEach(entry => {
          const { date, impressions, clicks, vcr, ctr } = entry;
          const metric = impressions || clicks || vcr || ctr || 0;
          daysArray.push(formatDateForTopMetrics(date));
          remainingPartArray.push(metric);
        });
        return [daysArray, remainingPartArray, firstPartArray];
      } else {
        data.forEach(entry => {
          const { date, impressions, clicks, vcr, ctr } = entry;
          const metric = impressions || clicks || vcr || ctr || 0;
          daysArray.push(formatDateForTopMetrics(date));
          firstPartArray.push(metric);
        });
        return [daysArray, firstPartArray];
      }
    },
    toggleCampaignFriendlyNameEdit(state: boolean) {
      this.showCampaignNameEdit = state;
    },
    async updateOrderFriendlyName(friendlyName: string) {
      this.friendlyNameToDisplay = friendlyName;
    },
    async copyOriginalOrderName() {
      try {
        await navigator.clipboard.writeText(this.selectedOrder.OrderName);
        this.orderNameCopied = true;
      } catch (e) {
        console.error('Failed to copy: ', e);
      }
    },
  },
  watch: {
    singleOrderParams: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handler(newVal: any): void {
        if (!newVal?.daterange) return;

        this.dateRange = newVal.daterange;
        this.selectedRange = newVal.daterange;
        this.callAllMetrics();
      },
      deep: true,
    },
  },
});
