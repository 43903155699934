
import Vue from 'vue';
import layoutUtils from '../../lib/home/layoutUtils';
import util from '../../util';

// Summary Components
import orderController from '../order/orderController.vue';
import summaryController from '../components/summary/summaryController.vue';
import homeController from '../components/homeController/homeController.vue';
import componentNotFound from '../components/charts/no-data/notFound.vue';
import genericTable from '../components/charts/tables/table.vue';
import siteImpactTable from '../components/charts/tables/siteImpactTable.vue';
import genericLineChart from '../components/charts/line/lineChart.vue';
import genericLineBarChartNew from '../components/charts/line/lineAndBarChartNew.vue';
import lineAndBarChart from '../components/charts/line/lineAndBarChart.vue';
import genericPie from '../components/charts/pie/pie.vue';
import progressBarTable from '../components/charts/tables/progressBarTable.vue';
import dmaZipMap from '../components/maps/dmaZipMap.vue';
import geofenceMap from '../components/maps/geofenceMap.vue';
import genericMap from '../components/maps/genericMap.vue';
import stationMap from '../components/maps/stationMap.vue';
import genericTopMetrics from '../components/charts/overviews/topMetrics.vue';
import genericBarChart from '../components/charts/bar/barChart.vue';
import campaignNav from '../components/campaignNav/campaignNav.vue';
import sideSummary from '../components/charts/overviews/sideSummary.vue';
import textCampaignSummary from '../components/charts/overviews/textCampaignSummary.vue';
import campaignStats from '../components/charts/overviews/campaignStats.vue';
import campaignReportDownload from '../components/charts/buttons/campaignReportDownload.vue';
import campaignOverview from '../components/charts/overviews/campaignOverview.vue';
import googlevideoSummaryView from '../components/tacticSummaryViews/googlevideo.vue';
import targetingList from '../components/charts/list/campaignTargeting/targetingList.vue';
import { LayoutSection, SectionComponent, CurrentSection } from '../../types/layout';
import editorDialog from '../components/layoutEditing/editorDialog.vue';
import slingNetworkLogosList from '../components/charts/list/slingNetworkLogosList.vue';
import tableList from '../components/charts/tables/tableList.vue';

export default Vue.extend({
  components: {
    orderController,
    summaryController,
    homeController,
    componentNotFound,
    genericTable,
    siteImpactTable,
    genericLineChart,
    genericLineBarChartNew,
    lineAndBarChart,
    genericPie,
    progressBarTable,
    dmaZipMap,
    geofenceMap,
    genericMap,
    stationMap,
    genericTopMetrics,
    genericBarChart,
    campaignNav,
    sideSummary,
    textCampaignSummary,
    campaignStats,
    campaignReportDownload,
    campaignOverview,
    googlevideoSummaryView,
    targetingList,
    editorDialog,
    slingNetworkLogosList,
    tableList,
  },
  props: ['currentSection', 'component', 'index', 'isInFilledRow'],
  data: (): {
    show: boolean;
    isMobile: boolean;
    fixedHeight: number;
    hideComponent: boolean;
  } => ({
    show: true,
    isMobile: false,
    fixedHeight: 541,
    hideComponent: false,
  }),
  mounted(): void {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  // ***
  // DASH-5207
  // this solution is not stable rn because we are not always getting
  // the data of the campaign earlier than props of the components
  // commenting out for now, maybe we'll need it later
  // ***
  // beforeUpdate(): void {
  //   console.log('before update', this.$refs[this.component.id] && this.component.hideIfNoData);
  //   if (this.$refs[this.component.id] && this.component.hideIfNoData) {
  //     this.hideComponent = this.$refs[this.component.id].firstElementChild === null;
  //   }
  // },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    theme(): any {
      return this.Theme;
    },
    componentCSS(): string {
      return this.show ? this.componentClass(this.component) : 'd-none';
    },
    lineBorder(): boolean {
      return this.Theme.config.chartBorder === 'line';
    },
    isEditing(): boolean {
      if (!this.$store.state.layoutEditor.componentId || !this.$store.state.layoutEditor.sectionId) return false;

      return (
        this.$store.state.layoutEditor?.componentId === this.component.id &&
        this.$store.state.layoutEditor?.sectionId === this.currentSection.id
      );
    },
    showEmptyModule(): boolean {
      let nextIsEmpty = false;
      // mobile always shows everything full width
      if (this.component && this.isInFilledRow && !this.isMobile) {
        // isInFilledRow is a mapped obj defining if a comp is in a filled row or not
        nextIsEmpty = this.isInFilledRow[this.component.id] ? !this.isInFilledRow[this.component.id] : false;
      }
      return nextIsEmpty;
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
    isForcedToBeFullWidth(): boolean {
      if (!this.component.id) return false;
      return (
        this.component.id.includes('tv2ottSideSummary') ||
        this.component.id.includes('calltrackingCampaignOverview') ||
        this.component.id.includes('googlevideoSideSummary')
      );
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reuseKey(section: any, component: any): string {
      if (['dmaZipMap', 'stationMap', 'genericMap', 'geofenceMap'].includes(component.name)) {
        return (
          section?.id + component.name + component.dataSource + component.title + this.$route.query.viewCampaigns // prevent map reuse across campaigns
        );
      }
      return section?.id + component.name + component.dataSource + component.title;
    },
    findComponent(section: LayoutSection, component: SectionComponent): string {
      if (!this.$options || !this.$options.components || !this.$options.components[component.name]) {
        // eslint-disable-next-line no-console
        console.info('component not found: ', component.name);
        return 'componentNotFound';
      }
      return component.name;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentAttributes(section: LayoutSection, component: SectionComponent): any {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { notes, ...cfg } = component; // todo: a bunch of attributes are passed to the DOM element because they are not props

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const attributes: any = {
        sectionConfig: section,
        componentConfig: component,
        theme: this.theme,
        componentHeight: this.fixedHeight,
        ...cfg,
      };
      return attributes;
    },
    componentClass(component: SectionComponent): string[] {
      const classes = [layoutUtils.componentClass(component, this.lineBorder, this.$vuetify.breakpoint)];

      if (this.hideComponent) {
        classes.push('d-none');
      }
      return classes;
    },
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
    sectionAttributes(section: LayoutSection, component: SectionComponent): void {
      const attrs = layoutUtils.sectionAttributes(this.Theme, component);

      // DASH-3633: temporal hack to set some summary modules to be full width
      // because we want them to be full-width for C360 and usual for old UI
      if (this.isForcedToBeFullWidth) {
        attrs['lg6'] = false;
        attrs['lg12'] = true;
      }
      return attrs;
    },
    setDisplay(display: boolean): void {
      this.show = display;
      this.hideComponent = !display;
    },
    onResize(): void {
      this.isMobile = window.innerWidth < 1264;
    },
  },
});
