
import Vue from 'vue';

export default Vue.extend({
  name: 'Notifications',
  props: ['componentData'],
  computed: {
    hideIfNoData() {
      return this.componentData?.hideIfNoData;
    },
  },
});
