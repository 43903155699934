
import Vue from 'vue';
import draggable from 'vuedraggable';
import OrderDetailsMetrics from '../components/orderDetailsMetrics.vue';
import orderDetailsChart from '../components/orderDetailsChart.vue';
import orderDetailsCampaignList from '../components/orderDetailsCampaignList.vue';
import orderDetailsPieChart from '../components/orderDetailsPieChart.vue';
import OrderDetailsWeekday from '../components/orderDetailsWeekdayTable.vue';
import OrderDetailsGeo from '../components/orderDetailsGeoTable.vue';
import OrderDetailsZipCode from '../components/orderDetailsZipCodeTable.vue';

export default Vue.extend({
  name: 'OrderSummaryController',
  props: ['sectionConfig', 'componentConfig', 'isExporting', 'isExportDynamic', 'exportData', 'exportContext'],
  components: {
    draggable,
    OrderDetailsMetrics,
    orderDetailsChart,
    orderDetailsPieChart,
    OrderDetailsWeekday,
    orderDetailsCampaignList,
    OrderDetailsGeo,
    OrderDetailsZipCode,
  },
  data: (): {
    availableProducts: Array<string>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedOrder: any;
    orderComponentConfig: any;
    comps: any;
  } => ({
    availableProducts: [],
    selectedOrder: null,
    orderComponentConfig: null,
    comps: [],
  }),
  created(): void {
    this.getSelectedOrder();
  },
  computed: {},
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getSelectedOrder(): any {
      // will get from $store

      this.orderComponentConfig =
        this.$store.state.customer.dynamicLayout.layoutCustomizations.config.components[0].components[0].configuration;
      const comps = JSON.parse(this.orderComponentConfig)[0].components;
      this.comps = comps;
    },
    getComponentClass(cid): string {
      return this.$store.state.order.hidingOrderModulesWhenNoImps && !['orderDetailsCampaignList', 'orderDetailsMetrics'].includes(cid)
        ? 'hidden-module'
        : '';
    },
  },
});
